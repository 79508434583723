//Customer booking view
import * as React from "react";
import { Box, Button, Stack, Typography, Grid, Card } from "@mui/material";

import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import HorizontalNonLinearStepper from "../seatView/HorizontalNonLinearStepper";

function Route({ item, index, containStep, setView, setItem, seatSelect }) {
  const [showSeatView, setShowSeatView] = React.useState(false);
  const [containStepView, setContainStepView] = React.useState(containStep);

  return (
    <Card
      key={index}
      sx={(theme) => ({
        p: 3,
        height: "fit-content",
        width: "100%",

        "&:hover": {
          background:
            theme.palette.mode === "light"
              ? "linear-gradient(to bottom right, hsla(210, 100%, 97%, 0.5) 25%, hsla(210, 100%, 90%, 0.3) 100%)"
              : "linear-gradient(to right bottom, hsla(210, 100%, 12%, 0.2) 25%, hsla(210, 100%, 16%, 0.2) 100%)",
          borderColor:
            theme.palette.mode === "light" ? "primary.light" : "primary.dark",
          boxShadow:
            theme.palette.mode === "light"
              ? "0px 2px 8px hsla(0, 0%, 0%, 0.1)"
              : "0px 1px 8px hsla(210, 100%, 25%, 0.5) ",
        },
      })}
    >
      <Box
        sx={{
          width: "100%",
          //   display: "flex",
          //   textAlign: "left",
          //   flexDirection: { xs: "column", md: "row" },
          //   alignItems: { md: "center" },
          //   gap: 2.5,
        }}
      >
        <div>
          <Typography
            color="text.primary"
            fontWeight="medium"
            variant="h5"
            gutterBottom
          >
            {item.bus_number +
              " - " +
              item.bus_type +
              " (" +
              item.depature_from +
              " - " +
              item.arrival_to +
              ")"}
          </Typography>
          <Typography color="text.secondary" variant="body2" sx={{ mb: 2 }}>
            Seat Layout - {item.structure.layout}
          </Typography>
        </div>
        <Grid container item sx={{ justifyContent: "space-between" }}>
          <Grid
            container
            item
            xs={12}
            md={8}
            sx={{
              justifyContent: "space-between",
            }}
          >
            <Grid container item md={5}>
              <Stack
                direction="column"
                justifyContent="center"
                alignItems="center"
                // spacing={2}
                useFlexGap
                sx={{ width: "100%", display: { xs: "flex" } }}
              >
                <Typography color="text.secondary" variant="h5">
                  {item.depature_time}
                </Typography>
                <Typography color="text.secondary" variant="h6">
                  {item.depature_from}
                </Typography>
              </Stack>
            </Grid>
            <Grid
              container
              item
              md={2}
              justifyContent="center"
              alignItems="center"
            >
              <ArrowRightAltIcon
                fontSize="medium"
                sx={{ mt: "1px", ml: "2px" }}
              />
            </Grid>
            <Grid container item md={5}>
              <Stack
                direction="column"
                justifyContent="center"
                alignItems="center"
                // spacing={2}
                useFlexGap
                sx={{ width: "100%", display: { xs: "flex" } }}
              >
                <Typography color="text.secondary" variant="h5">
                  {item.arrival_time}
                </Typography>
                <Typography color="text.secondary" variant="h6">
                  {item.arrival_to}
                </Typography>
              </Stack>
            </Grid>
          </Grid>
          <Grid container item xs={12} md={4}>
            <Stack
              direction="column"
              justifyContent="center"
              alignItems="center"
              // spacing={2}
              useFlexGap
              sx={{ width: "100%", display: { xs: "flex" } }}
            >
              <Typography color="text.main" variant="h5">
                Rs {item.fee}
              </Typography>
              <Typography color="text.secondary" variant="body1">
                {item.status ? "Every day available" : ""}
              </Typography>
              <Button
                onClick={() => {
                  setView("seatView");
                  setItem(item);
                }}
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                hidden={seatSelect}
              >
                {!containStepView || !showSeatView ? "Select Seat" : "Hide"}
              </Button>
            </Stack>
          </Grid>
          {containStepView && showSeatView ? (
            <HorizontalNonLinearStepper item={item} />
          ) : (
            ""
          )}
        </Grid>
      </Box>
    </Card>
  );
}

export default Route;
