import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Container,
  Grid,
  Card,
  Paper,
  Select,
  FormControl,
  MenuItem,
} from "@mui/material";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import dayjs from "dayjs";

//assets
import loginBackground from "../../../assets/images/login_background.jpg";
// import loginBackground from "../../../assets/images/C5C_5508.jpg";

import { getLocationList } from "../../../services/busServices";
import Loading from "../../../components/utilities/Loading/Loading";

export default function Hero({
  loading,
  handleSubmit,
  setPickup,
  setDrop,
  setDate,
  pickup,
  drop,
  date,
}) {
  const [allLocations, setAllLocations] = useState({});

  const locationData = async () => {
    try {
      // setLoading(true);
      const res = await getLocationList();
      // console.log(res);
      setAllLocations(res);
      // setLoading(false);
    } catch (error) {
      console.error("Error fetching location details:", error.message);
    }
  };
  // console.log(loading, "in Hero");
  useEffect(() => {
    locationData();
  }, []);

  const handlePickup = (event) => {
    setPickup(event.target.value);
  };

  const handleDrop = (event) => {
    setDrop(event.target.value);
  };

  return loading ? (
    <Loading />
  ) : (
    <Box id="hero">
      <Container>
        <h1>Booking From Call</h1>
        <Card
          id="image"
          sx={{
            width: "100%",
          }}
        >
          <Grid container component="main" p={2}>
            <Grid item xs={12} sm={12} md={12} component={Paper} elevation={6}>
              <Box
                component="form"
                noValidate
                onSubmit={handleSubmit}
                sx={{
                  my: 8,
                  mx: 4,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <FormControl sx={{ margin: "normal" }} fullWidth>
                      <Select
                        value={pickup}
                        onChange={handlePickup}
                        displayEmpty
                        inputProps={{ "aria-label": "Without label" }}
                        sx={{
                          borderRadius: "2px",
                          outline: "none",
                          color: "text.primary",
                        }}
                      >
                        <MenuItem value="" disabled>
                          Pickup Point
                        </MenuItem>
                        {Object.entries(allLocations).map(
                          ([location, value]) => (
                            <MenuItem key={location} value={location}>
                              {location}
                            </MenuItem>
                          )
                        )}
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <FormControl sx={{ margin: "normal" }} fullWidth>
                      <Select
                        value={drop}
                        onChange={handleDrop}
                        displayEmpty
                        inputProps={{ "aria-label": "Without label" }}
                      >
                        <MenuItem value="" disabled>
                          Dropping Point
                        </MenuItem>
                        {Object.entries(allLocations).map(
                          ([location, value]) => (
                            <MenuItem key={location} value={location}>
                              {location}
                            </MenuItem>
                          )
                        )}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <Container sx={{ px: "0px !important" }}>
                    <DatePicker
                      minDate={dayjs()} // Set minDate to today using dayjs
                      value={date}
                      onChange={(newValue) => setDate(newValue)}
                      sx={{
                        marginTop: "15px",
                        width: "100%",
                      }}
                    />
                  </Container>
                </LocalizationProvider>
                <Button type="submit" variant="contained" sx={{ mt: 3, mb: 2 }}>
                  Find Tickets
                </Button>{" "}
              </Box>
            </Grid>
          </Grid>
        </Card>
      </Container>
    </Box>
  );
}
