import * as React from "react";
import Box from "@mui/material/Box";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material/MenuItem";
import Drawer from "@mui/material/Drawer";
import MenuIcon from "@mui/icons-material/Menu";
import Stack from "@mui/material/Stack";

//assets
import logocrop from "../../../assets/images/logo-crop-removebg.png";

const logoStyle = {
  width: "50px",
  height: "auto",
  cursor: "pointer",
};

function AppAppBar({ setView }) {
  const [open, setOpen] = React.useState(false);

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  const handleMenuItemClick = (view) => () => {
    setView(view);
    toggleDrawer(false)(); // Close the drawer when a menu item is clicked
  };

  return (
    <div>
      <AppBar
        position="fixed"
        sx={{
          boxShadow: 0,
          bgcolor: "transparent",
          backgroundImage: "none",
          mt: 2,
        }}
      >
        <Container sx={{ maxWidth: "none !important" }}>
          <Toolbar
            variant="regular"
            sx={(theme) => ({
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              flexShrink: 0,
              borderRadius: "999px",
              bgcolor:
                theme.palette.mode === "light"
                  ? "rgba(255, 255, 255, 0.4)"
                  : "rgba(0, 0, 0, 0.4)",
              backdropFilter: "blur(24px)",
              maxHeight: 40,
              border: "1px solid",
              borderColor: "divider",
              boxShadow:
                theme.palette.mode === "light"
                  ? `0 0 1px rgba(85, 166, 246, 0.1), 1px 1.5px 2px -1px rgba(85, 166, 246, 0.15), 4px 4px 12px -2.5px rgba(85, 166, 246, 0.15)`
                  : "0 0 1px rgba(2, 31, 59, 0.7), 1px 1.5px 2px -1px rgba(2, 31, 59, 0.65), 4px 4px 12px -2.5px rgba(2, 31, 59, 0.65)",
            })}
          >
            <Box
              sx={{
                flexGrow: 1,
                display: "flex",
                alignItems: "center",
                ml: "-18px",
                px: 0,
              }}
            >
              <img src={logocrop} style={logoStyle} alt="logo of sitemark" />
              <Stack>
                <Typography
                  sx={{ color: "#171f46", variant: "h6", fontWeight: 600 }}
                >
                  VISHMITHA
                </Typography>
                <Typography sx={{ color: "#171f46", variant: "body2" }}>
                  HIGHWAY EXPRESS
                </Typography>
              </Stack>
              <Box
                sx={{ display: { xs: "none", md: "flex", direction: "end" } }}
              >
                <MenuItem
                  onClick={handleMenuItemClick("home")}
                  sx={{ py: "6px", px: "12px", marginLeft: 10 }}
                >
                  <Typography variant="body2" color="text.primary">
                    Home
                  </Typography>
                </MenuItem>
                <MenuItem
                  onClick={handleMenuItemClick("about")}
                  sx={{ py: "6px", px: "12px" }}
                >
                  <Typography variant="body2" color="text.primary">
                    About
                  </Typography>
                </MenuItem>
                <MenuItem
                  onClick={handleMenuItemClick("contact")}
                  sx={{ py: "6px", px: "12px" }}
                >
                  <Typography variant="body2" color="text.primary">
                    Contact
                  </Typography>
                </MenuItem>
                <MenuItem
                  onClick={handleMenuItemClick("policyPage")}
                  sx={{ py: "6px", px: "12px" }}
                >
                  <Typography variant="body2" color="text.primary">
                    Our Policies
                  </Typography>
                </MenuItem>

                {/* <Button
                  color="secondary"
                  variant="contained"
                  size="small"
                  component="a"
                  href="#"
                  target="_blank"
                  display="false"
                  sx={{ py: "6px", px: "12px" }}
                >
                  Buy Tickets
                </Button> */}
              </Box>
            </Box>
            <Box
              sx={{
                display: { xs: "none", md: "flex" },
                gap: 0.5,
                alignItems: "center",
              }}
            >
              <Button
                color="primary"
                variant="contained"
                size="small"
                component="a"
                href="/login"
              >
                Sign in
              </Button>
              {/* <Button
                color="primary"
                variant="text"
                size="small"
                component="a"
                href="/material-ui/getting-started/templates/sign-up/"
                target="_blank"
                display="false"
              >
                Sign up
              </Button> */}
            </Box>
            <Box sx={{ display: { sm: "", md: "none" } }}>
              <Button
                variant="text"
                color="primary"
                aria-label="menu"
                onClick={toggleDrawer(true)}
                sx={{ minWidth: "30px", p: "4px" }}
              >
                <MenuIcon />
              </Button>
              <Drawer anchor="right" open={open} onClose={toggleDrawer(false)}>
                <Box
                  sx={{
                    minWidth: "60dvw",
                    p: 2,
                    backgroundColor: "background.paper",
                    flexGrow: 1,
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "end",
                      flexGrow: 1,
                    }}
                  ></Box>
                  <MenuItem onClick={handleMenuItemClick("home")}>
                    Home
                  </MenuItem>
                  <MenuItem onClick={handleMenuItemClick("about")}>
                    About
                  </MenuItem>
                  <MenuItem onClick={handleMenuItemClick("contact")}>
                    Contact
                  </MenuItem>
                  <MenuItem onClick={handleMenuItemClick("policyPage")}>
                    Our Policies
                  </MenuItem>
                  <Divider />
                  {/* <MenuItem>
                    <Button
                      color="secondary"
                      variant="contained"
                      component="a"
                      href="#"
                      target="_blank"
                      sx={{ width: "100%" }}
                    >
                      Buy Tickets
                    </Button>
                  </MenuItem> */}
                  <Divider />
                  {/* <MenuItem>
                    <Button
                      color="primary"
                      variant="contained"
                      component="a"
                      href="/material-ui/getting-started/templates/sign-up/"
                      target="_blank"
                      sx={{ width: "100%" }}
                    >
                      Sign up
                    </Button>
                  </MenuItem> */}
                  <MenuItem>
                    <Button
                      color="primary"
                      variant="outlined"
                      component="a"
                      href="/login"
                      sx={{ width: "100%" }}
                    >
                      Sign in
                    </Button>
                  </MenuItem>
                </Box>
              </Drawer>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
    </div>
  );
}

export default AppAppBar;
