import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Hero from "../utilities/Hero/Hero";
import Routes from "../Routes/Routes";


function Home({
  setView,
  setItem,
  loading,
  //
  handleSubmit,
  setPickup,
  setDrop,
  setDate,
  pickup,
  drop,
  date,
  allBusses,
  title,
  seatSelect,
}) {
  return (
    <React.Fragment>
      <Hero
        loading={loading}
        handleSubmit={handleSubmit}
        setPickup={setPickup}
        setDrop={setDrop}
        setDate={setDate}
        pickup={pickup}
        drop={drop}
        date={date}
      />
      <Routes
        setView={setView}
        setItem={setItem}
        allBusses={allBusses}
        loading={loading}
        title={title}
        seatSelect={seatSelect}
      />
    </React.Fragment>
  );
}

export default Home;
