import {
  Grid,
  Card,
  Box,
  FormControl,
  Select,
  MenuItem,
  Container,
  Button,
} from "@mui/material";
import SideBar from "../../components/sidebar/SideBar";
import { ToastContainer } from "react-toastify";
import ForumTable from "../../components/utilities/Table/ForumTable";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import format from "date-fns/format";
import { useEffect, useState } from "react";
import {
  fetchAllBuses,
  fetchBooking,
  updateBookings,
} from "../../services/busServices";

import { CheckCircle, HourglassEmpty, Error } from "@material-ui/icons";
import LocalAtmIcon from "@mui/icons-material/LocalAtm";
import HowToRegIcon from "@mui/icons-material/HowToReg";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loading from "../../components/utilities/Loading/Loading";
import dayjs from "dayjs";

const handleFilterClick = () => {
  console.log("filter click");
};

const BookingDetails = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  //common
  const [loading, setLoading] = useState(true);
  //spesific
  const [allBuses, setAllBuses] = useState({});
  const [bus, setBus] = useState("");
  const [date, setDate] = useState(null);
  const [rows, setRows] = useState([]);
  const [selectedRow, setSelectedRow] = useState({});

  const headCells = [
    {
      id: "passenger_name",
      numeric: false,
      disablePadding: false,
      label: "Name",
    },
    {
      id: "mobile_no",
      numeric: false,
      disablePadding: false,
      label: "Mobile Number",
    },
    { id: "date", numeric: false, disablePadding: false, label: "Date" },

    {
      id: "from",
      numeric: false,
      disablePadding: false,
      label: "Departure",
    },
    { id: "to", numeric: false, disablePadding: false, label: "Arrival" },
    {
      id: "fee",
      numeric: false,
      disablePadding: false,
      label: "Fee (Rs)",
    },
    {
      id: "seats",
      numeric: false,
      disablePadding: false,
      label: "Seats",
      render: (value) => {
        // Check if seats exist and render comma-separated list
        if (value && value.length > 0) {
          const seatsList = Object.keys(value[0]).join(", "); // Assuming each seat object has a single key (seat number)
          return seatsList;
        }
        return "-";
      },
    },
    {
      id: "payment_method",
      numeric: false,
      disablePadding: false,
      label: "Payment Method",
      style: (value) => ({
        backgroundColor:
          value === "cash" ? "green" : value === "upload_slip" ? "red" : "",
        color: "white",
      }),
      clickable: true,
      disable: (value) => {
        if (user && value === "upload_slip" && user.role === "admin") {
          return false;
        } else {
          return true;
        }
      },
      render: (value) => {
        switch (value) {
          case "cash":
            return <LocalAtmIcon />;
          case "upload_slip":
            return <HourglassEmpty />;
          default:
            return <CheckCircle />;
        }
      },
      openDialog: true,
    },
    {
      id: "status",
      numeric: false,
      disablePadding: false,
      label: "Status",
      style: (value) => ({
        backgroundColor:
          value === "booked"
            ? "green"
            : value === "processing"
            ? "red"
            : value === "completed"
            ? "blue"
            : "orange",
        color: "white",
      }),
      clickable: true,
      disable: (value) => {
        if (
          (value === "booked" || value === "done") &&
          user &&
          user.role === "conductor"
        ) {
          return false;
        } else {
          return true;
        }
      },
      render: (value) => {
        switch (value) {
          case "done":
            // return <CheckCircle />;
            return "Done";
          case "processing":
            // return <HourglassEmpty />;
            return "Processing";
          case "booked":
            // return <HowToRegIcon />;
            return "Booked";
          case "completed":
            // return <HowToRegIcon />;
            return "Completed";
          default:
            return <Error />;
        }
      },
      openDialog: false,
      onClick: (row) => handleStatusClick(row),
    },
  ];

  const handleBus = (e) => {
    e.preventDefault();
    setBus(e.target.value);
    console.log(e, "handlebus");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validation checks
    if (!bus || !date) {
      toast.error("Please select bus, and date");
      return;
    }

    setLoading(true);

    // Check if date is valid
    const formattedDate = date ? format(new Date(date), "yyyy-MM-dd") : "";
    if (!formattedDate) {
      toast.error("Invalid date selected");
      setLoading(false);
      return;
    }

    console.log("Form submitted:", date, formattedDate);
    console.log(bus, date);

    loadBus(bus, formattedDate);
  };

  const loadBus = async (bus, d) => {
    console.log("In load bus::::::", bus, d);
    const formattedDate = d ? format(new Date(d), "yyyy-MM-dd") : "";
    try {
      console.log("Bus Route Data: load before", formattedDate);
      const busBookingData = await fetchBooking(bus, formattedDate);
      console.log("Bus Route Data:", busBookingData, formattedDate);
      setRows(busBookingData);
      setLoading(false);

      // Further processing/rendering of bus route data
    } catch (error) {
      console.error("Error searching bus route:", error.message);
      setLoading(false);

      // Handle error (e.g., display error message to the user)
    }
  };
  const handleStatusClick = async (row) => {
    console.log(row, "handleStatusClick");
    try {
      const updatedRow = { ...row, status: "completed" };

      await updateBookings(updatedRow);

      toast.success("Status Changed", {
        position: "top-right",
      });

      const formattedDate = date ? format(new Date(date), "yyyy-MM-dd") : "";
      const busBookingData = await fetchBooking(bus, formattedDate);
      console.log(busBookingData, "In handle Status change");
      setRows(busBookingData);
      setLoading(false);
    } catch (error) {
      toast.error("Status Changed Failed", {
        position: "top-right",
      });
    }
  };

  const handleAcceptClick = async () => {
    console.log("Payment Accepted: ", selectedRow);
    try {
      const updatedRow = {
        ...selectedRow,
        payment_method: "",
        status: "booked",
      };
      await updateBookings(updatedRow);

      toast.success("Payment Accepted", {
        position: "top-right",
      });

      const formattedDate = date ? format(new Date(date), "yyyy-MM-dd") : "";
      const busBookingData = await fetchBooking(bus, formattedDate);
      setRows(busBookingData);
      setLoading(false);
    } catch (error) {
      toast.error("Payment Acception Not Success", {
        position: "top-right",
      });
    }
  };

  const handleHoldClick = async () => {
    console.log("Booking Hold: ", selectedRow);
    try {
      const updatedRow = {
        ...selectedRow,
        payment_method: "",
        status: "hold",
      };
      await updateBookings(updatedRow);

      toast.success("Booking Hold", {
        position: "top-right",
      });

      const formattedDate = date ? format(new Date(date), "yyyy-MM-dd") : "";
      const busBookingData = await fetchBooking(bus, formattedDate);
      setRows(busBookingData);
      setLoading(false);
    } catch (error) {
      toast.error("Hold process Not Success", {
        position: "top-right",
      });
    }
  };

  const buses = async () => {
    try {
      // setLoading(true);
      const res = await fetchAllBuses();
      console.log(res);
      setAllBuses(res);
      setLoading(false);
      // setLoading(false);
    } catch (error) {
      console.error("Error fetching bookings:", error.message);
    }
  };

  const checkLoad = async () => {
    if (user && user.role === "conductor") {
      const today = new Date();
      setDate(dayjs(today));
      setBus(user.firstName);
      loadBus(user.firstName, today);
    }
  };

  useEffect(() => {
    buses();
    checkLoad();
  }, []);

  return (
    <SideBar userRole={user && user.role}>
      <ToastContainer />
      {loading ? (
        <Loading />
      ) : (
        <Grid container sx={{ height: "100vh" }}>
          <Grid item sx={{ width: "100%", padding: 1 }}>
            <Card sx={{ marginBottom: 1 }}>
              <Box
                component="form"
                noValidate
                onSubmit={handleSubmit}
                sx={{
                  margin: 1,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={5}>
                    <FormControl sx={{ margin: "normal" }} fullWidth>
                      <Select
                        value={bus}
                        onChange={handleBus}
                        displayEmpty
                        inputProps={{ "aria-label": "Without label" }}
                        sx={{
                          borderRadius: "2px",
                          outline: "none",
                          color: "text.primary",
                        }}
                        disabled={user.role === "conductor" ? true : false}
                      >
                        <MenuItem value="" disabled>
                          Select Bus
                        </MenuItem>
                        {Object.entries(allBuses).map(([i, value]) => (
                          <MenuItem key={i} value={value.id}>
                            {value.id}
                            {console.log(value)}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} sm={5}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <Container sx={{ px: "0px !important" }}>
                        <DatePicker
                          value={date}
                          onChange={(newValue) => setDate(newValue)}
                          sx={{
                            width: "100%",
                          }}
                        />
                      </Container>
                    </LocalizationProvider>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={2}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Button type="submit" variant="contained">
                      Find Bookings
                    </Button>{" "}
                  </Grid>
                </Grid>
              </Box>
            </Card>
            <Card className="booking-section">
              <ForumTable
                title={"Bookings"}
                handleFilterClick={handleFilterClick}
                headCells={headCells}
                rows={rows}
                searchKey={bus}
                handleAccept={handleAcceptClick}
                handleHold={handleHoldClick}
                setSelectedRow={setSelectedRow}
              ></ForumTable>
            </Card>
          </Grid>
        </Grid>
      )}
    </SideBar>
  );
};

export default BookingDetails;
