import React, { useEffect, useState } from "react";
import { Container, Stack, Typography, Grid } from "@mui/material";
import Route from "../Route/Route";
import Loading from "../utilities/Loading/Loading";
import Card from "@mui/material/Card";

function Routes({ setView, setItem, allBusses, loading, title, seatSelect }) {
  const [selectedBus, setSelectedBus] = useState([]);
  // console.log(allBusses.length);

  const selectBus = (data) => {
    console.log("bus selected", data);
    setSelectedBus(data);
  };

  return (
    <Container id="features" sx={{ py: { xs: 1, sm: 1, md: 2 } }}>
      <Typography
        variant="h1"
        sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "row" },
          alignItems: "center",
          fontSize: "clamp(3rem, 10vw, 3.5rem)",
          justifyContent: "center",
          color: (theme) =>
            theme.palette.mode === "light" ? "primary.main" : "primary.light",
          paddingBottom: 2,
        }}
      >
        {title}
      </Typography>
      <Grid container spacing={6}>
        <Grid item xs={12} md={12}>
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="flex-start"
            spacing={2}
            useFlexGap
            sx={{ width: "100%", display: { xs: "flex" } }}
          >
            {/* {items.map((item, index) => (
              <Route item={item} index={index} />
            ))} */}

            {loading ? (
              <Loading />
            ) : allBusses.length === 0 ? (
              <Container sx={{ display: "flex", justifyContent: "center" }}>
                <Card sx={{ padding: 4 }}>
                  <Typography variant="body1">
                    Sorry There Are No Buses...!
                  </Typography>
                </Card>
              </Container>
            ) : (
              allBusses?.map((bus, index) => (
                <Route
                  containStep={false}
                  item={bus}
                  index={index}
                  key={bus.id}
                  selectBus={selectBus}
                  setView={setView}
                  setItem={setItem}
                  seatSelect={seatSelect}
                />
              ))
            )}
          </Stack>
        </Grid>
      </Grid>
    </Container>
  );
}

export default Routes;
