import {
  Grid,
  CardContent,
  Card,
  Typography,
  Stack,
  Avatar,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import { BarChart } from "@mui/x-charts/BarChart";
import { axisClasses } from "@mui/x-charts/ChartsAxis";
import { Gauge, gaugeClasses } from "@mui/x-charts/Gauge";
import { PieChart } from "@mui/x-charts/PieChart";
import ArrowDownwardIcon from "@mui/icons-material/ArrowCircleDown";
import ArrowUpwardIcon from "@mui/icons-material/ArrowCircleUp";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import BookOnlineIcon from "@mui/icons-material/BookOnline";
import DirectionsBusIcon from "@mui/icons-material/DirectionsBus";
import React, { useEffect, useState } from "react";
import { getUniqueBookingsByNIC, getTotalBookingsForToday } from "../../services/busServices";
import Loading from "../utilities/Loading/Loading";

const settings = {
  width: 200,
  height: 200,
  value: 60,
};

const chartSetting = {
  yAxis: [
    {
      label: "rainfall (mm)",
    },
  ],
  width: 500,
  height: 300,
  sx: {
    [`.${axisClasses.left} .${axisClasses.label}`]: {
      transform: "translate(-20px, 0)",
    },
  },
};
const dataset = [
  // Your dataset
];

const valueFormatter = (value) => `${value}mm`;

function Dash({ diff = 6, trend = "up", value = "8" }) {
  const TrendIcon = trend === "up" ? ArrowUpwardIcon : ArrowDownwardIcon;
  const trendColor = trend === "up" ? "#4caf50" : "#ef5350";
  const [lengthCustomer, setLengthCustomer] = useState();
  const [todayBookingCount, setTodayBookingCount] = useState();
  const [loading, setLoading] = useState(false); // Added loading state

  const getUniqueBookingsByNICData = async () => {
    setLoading(true); // Set loading to true when starting to fetch data
    try {
      const res = await getUniqueBookingsByNIC();
      const todayBookingCountRes = await getTotalBookingsForToday();
      console.log(res);
      // console.log(todayBookingCountRes);
      setLengthCustomer(res.length);
      setTodayBookingCount(todayBookingCountRes);
    } catch (error) {
      console.error("Error fetching bookings nic details:", error.message);
    } finally {
      setLoading(false); // Set loading to false once data is fetched or an error occurs
    }
  };

  useEffect(() => {
    getUniqueBookingsByNICData();
  }, []);

  return (
    <Grid
      container
      sx={{ height: "100vh", display: "flex", flexDirection: "column" }}
    >
      <Grid item sx={{ display: "flex", flexDirection: "row" }}>
        <Grid item xs={12} md={4} sx={{ width: "100%", padding: 1 }}>
          <Card sx={{ height: "100%" }}>
            <CardContent>
              <Stack spacing={3}>
                <Stack
                  direction="row"
                  sx={{
                    alignItems: "flex-start",
                    justifyContent: "space-between",
                  }}
                  spacing={3}
                >
                  <Stack spacing={1}>
                    <Typography color="secondary" variant="h5">
                      Total Customers
                    </Typography>
                    <Typography variant="h4">{lengthCustomer}</Typography>
                  </Stack>
                  <Avatar
                    sx={{
                      backgroundColor: "#ba68c8",
                      height: "56px",
                      width: "56px",
                    }}
                  >
                    <SupportAgentIcon fontSize="medium" />
                  </Avatar>
                </Stack>
                {diff ? (
                  <Stack
                    sx={{ alignItems: "center" }}
                    direction="row"
                    spacing={2}
                  >
                    <Stack
                      sx={{ alignItems: "center" }}
                      direction="row"
                      spacing={0.5}
                    >
                      <TrendIcon sx={{ color: trendColor }} fontSize="medium" />
                      <Typography color={trendColor} variant="body2">
                        {/* {diff}% */}
                      </Typography>
                    </Stack>
                    <Typography color="main" variant="caption">
                      Since start
                    </Typography>
                  </Stack>
                ) : null}
              </Stack>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={4} sx={{ width: "100%", padding: 1 }}>
          <Card sx={{ height: "100%" }}>
            <CardContent>
              <Stack spacing={2}>
                <Stack
                  direction="row"
                  sx={{
                    alignItems: "flex-start",
                    justifyContent: "space-between",
                  }}
                  spacing={3}
                >
                  <Stack spacing={1}>
                    <Typography color="secondary" variant="h5">
                      Today Total Bookings
                    </Typography>
                    <Typography variant="h4">{todayBookingCount}</Typography>
                  </Stack>
                  <Avatar
                    sx={{
                      backgroundColor: "#ff9800",
                      height: "56px",
                      width: "56px",
                    }}
                  >
                    <BookOnlineIcon fontSize="medium" />
                  </Avatar>
                </Stack>
                {diff ? (
                  <Stack
                    sx={{ alignItems: "center" }}
                    direction="row"
                    spacing={2}
                  >
                    <Stack
                      sx={{ alignItems: "center" }}
                      direction="row"
                      spacing={0.5}
                    >
                      <TrendIcon sx={{ color: trendColor }} fontSize="medium" />
                      <Typography color={trendColor} variant="body2">
                        {/* {diff}% */}
                      </Typography>
                    </Stack>
                    <Typography color="main" variant="caption">
                      Today
                    </Typography>
                  </Stack>
                ) : null}
              </Stack>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={4} sx={{ width: "100%", padding: 1 }}>
          <Card sx={{ height: "100%" }}>
            <CardContent>
              <Stack spacing={2}>
                <Stack
                  direction="row"
                  sx={{
                    alignItems: "flex-start",
                    justifyContent: "space-between",
                  }}
                  spacing={3}
                >
                  <Stack spacing={1}>
                    <Typography color="secondary" variant="h5">
                      Total Busses
                    </Typography>
                    <Typography variant="h4">{1}</Typography>
                  </Stack>
                  <Avatar
                    sx={{
                      backgroundColor: "#ff9800",
                      height: "56px",
                      width: "56px",
                    }}
                  >
                    <DirectionsBusIcon fontSize="medium" />
                  </Avatar>
                </Stack>
                {diff ? (
                  <Stack
                    sx={{ alignItems: "center" }}
                    direction="row"
                    spacing={2}
                  >
                    <Stack
                      sx={{ alignItems: "center" }}
                      direction="row"
                      spacing={0.5}
                    >
                      <TrendIcon sx={{ color: trendColor }} fontSize="medium" />
                      <Typography color={trendColor} variant="body2">
                        {/* {diff}% */}
                      </Typography>
                    </Stack>
                    <Typography color="main" variant="caption">
                      Your own busses
                    </Typography>
                  </Stack>
                ) : null}
              </Stack>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      {/* Add loading indicator */}
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
       <Loading/>
      </Backdrop>
      {/* Optionally include the chart and pie components here */}
    </Grid>
  );
}

export default Dash;
