// import axios from "axios";
// const USER_URL = "http://localhost:8080/api/v1";

// const getToken = () => {
//   return localStorage.getItem("USER_KEY");
// };

// export const getUsers = () => {
//   // return axios.get(USER_URL);
//   return axios({
//     method: "GET",
//     url: `${USER_URL}/users`,
//     headers: {
//       Authorization: "Bearer " + getToken(),
//     },
//   });
// };

// export const getProfile = (id) => {
//   // return axios.get(USER_URL);
//   return axios({
//     method: "GET",
//     url: `${USER_URL}/users/${id}`,
//     headers: {
//       Authorization: "Bearer " + getToken(),
//     },
//   });
// };

// export const updateProfile = (profile,role) => {
//   // return axios.get(USER_URL);
//   return axios({
//     method: "PUT",
//     url: `${USER_URL}/updateProfile/${role}`,
//     headers: {
//       Authorization: "Bearer " + getToken(),
//     },
//     data:profile,
//   });
// };

// export const deactivateUser = (id) => {
//   // return axios.get(USER_URL);
//   return axios({
//     method: "PUT",
//     url: `${USER_URL}/deactivateUser/${id}`,
//     headers: {
//       Authorization: "Bearer " + getToken(),
//     },
//   });
// };

// export const updatePassword = (password,id) => {
//   console.log(password);
//   // return axios.get(USER_URL);
//   return axios({
//     method: "PUT",
//     url: `${USER_URL}/changePassword/${id}`,
//     headers: {
//       Authorization: "Bearer " + getToken(),
//     },
//     data:password,
//   });
// };
import { auth, db } from "../config/firebase";
import { getDoc, doc } from "firebase/firestore";
import { app_log } from "../config/logs";

export const fetchuserDetails = async () => {
    try {
        return new Promise((resolve, reject) => {
            auth.onAuthStateChanged(async (user) => {
                // console.log(user);

                if (user) {
                    const docRef = doc(db, "users", user.uid);
                    const docSnap = await getDoc(docRef);
                    if (docSnap.exists()) {
                        // app_log('info', "User Data", "fetchuserDetails",docSnap.data());
                        resolve(docSnap.data());
                    } else {
                        // app_log('error', "User data not found", "fetchuserDetails");
                        resolve(null);
                    }
                } else {
                    // app_log('error', "User is not logged in", "fetchuserDetails");
                    resolve(null);
                }
            });
        });
    } catch (error) {
        // app_log('error', "Error fetching user details:", error.message);

        throw error;
    }
};