import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";
import SidebarMenu from "./SidebarMenu";
import { handleLogout } from "../../services/authService";
import MenuIcon from "@mui/icons-material/Menu";
import DashboardIcon from "@mui/icons-material/Dashboard";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import DirectionsBusFilledIcon from "@mui/icons-material/DirectionsBusFilled";
import ConfirmationNumberIcon from "@mui/icons-material/ConfirmationNumber";
import AssessmentIcon from "@mui/icons-material/Assessment";
import SettingsApplicationsIcon from "@mui/icons-material/SettingsApplications";
import ContactsIcon from "@mui/icons-material/Contacts";
import LockIcon from "@mui/icons-material/Lock";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import AirlineSeatReclineNormalIcon from "@mui/icons-material/AirlineSeatReclineNormal";

const routes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: <DashboardIcon />,
    userRole: "admin",
  },
  {
    path: "/users",
    name: "Customers",
    icon: <ContactsIcon />,
    userRole: "admin",
  },
  {
    path: "/bus",
    name: "Bus",
    icon: <DirectionsBusFilledIcon />,
    userRole: "admin",
  },
  {
    path: "/bookings",
    name: "Bookings",
    icon: <ConfirmationNumberIcon />,
    userRole: "both",
  },
  {
    path: "/book-now",
    name: "Booking From Call",
    icon: <DirectionsBusFilledIcon />,
    userRole: "admin",
  },
  {
    path: "/",
    name: "Logout",
    icon: <ExitToAppIcon onClick={handleLogout} />,
    userRole: "both",
  },
];

const SideBar = ({ children, userRole }) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);

  const showAnimation = {
    hidden: {
      width: 0,
      opacity: 0,
      transition: {
        duration: 0.5,
      },
    },
    show: {
      opacity: 1,
      width: "auto",
      transition: {
        duration: 0.5,
      },
    },
  };

  return (
    <div className="main-container">
      <motion.div
        animate={{
          width: isOpen ? "250px" : "50px",
          transition: {
            duration: 0.5,
            type: "spring",
            damping: 10,
          },
        }}
        className="sidebar"
        onMouseEnter={() => setIsOpen(true)}
        onMouseLeave={() => setIsOpen(false)}
        style={{
          borderRight: "1px solid #ddd",
          borderBottom: "1px solid #ddd",
          borderTop: "1px solid #ddd",
          borderLeft: "1px solid #ddd",
          borderRadius: "8px",
          overflow: "hidden",
          backgroundColor: "#f4f4f4",
          color: "black",
          boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
          display: "flex",
          flexDirection: "column",
          height: "100vh",
        }}
      >
        <div className="top_section">
          <AnimatePresence>
            {isOpen && (
              <motion.h1
                variants={showAnimation}
                initial="hidden"
                animate="show"
                exit="hidden"
                className="logo"
                style={{ margin: 0, color: "black" }}
              >
                VISHMITHA 
              </motion.h1>
            )}
          </AnimatePresence>

          <div
            className="bars"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <MenuIcon onClick={toggle} style={{ color: "black" }} />
          </div>
        </div>
        <section className="routes">
          {routes.map((route, index) => {
            if (route.userRole === "both" || route.userRole === userRole) {
              if (route.subRoutes) {
                return (
                  <SidebarMenu
                    key={index}
                    setIsOpen={setIsOpen}
                    route={route}
                    showAnimation={showAnimation}
                    isOpen={isOpen}
                    userRole={userRole}
                  />
                );
              }

              return (
                <NavLink
                  to={route.path}
                  key={index}
                  className={({ isActive }) =>
                    `link ${isActive ? "active" : ""}`
                  }
                  style={{ textDecoration: "none" }}
                >
                  <div className="icon" style={{ color: "black" }}>
                    {route.icon}
                  </div>
                  <AnimatePresence>
                    {isOpen && (
                      <motion.div
                        variants={showAnimation}
                        initial="hidden"
                        animate="show"
                        exit="hidden"
                        className="link_text"
                        style={{ color: "black" }}
                      >
                        {route.name}
                      </motion.div>
                    )}
                  </AnimatePresence>
                </NavLink>
              );
            }
            return null;
          })}
        </section>

        <div
          className="sidebar-footer"
          style={{
            marginTop: "auto",
            padding: "10px",
            backgroundColor: "#f4f4f4",
            borderTop: "1px solid #ddd",
            textAlign: "center",
            fontSize: "12px",
            color: "black",
            display: isOpen ? "block" : "none",
          }}
        >
          <div
            style={{ fontWeight: "bold", marginBottom: "5px", color: "black" }}
          >
            VISHMITHA HIGHWAY EXPRESS
          </div>
          <div
            style={{
              fontStyle: "italic",
              marginBottom: "10px",
              color: "black",
            }}
          >
            Check yout booking details
          </div>
          <div style={{ color: "black" }}>
            METADEW TECHNOLOGIES | Copyright © 2024. All rights reserved.
          </div>
        </div>
      </motion.div>
      <motion.div
        style={{ width: "100%" }}
        animate={{
          marginLeft: isOpen ? "250px" : "50px",
          transition: {
            duration: 0.5,
            type: "spring",
            damping: 10,
          },
        }}
      >
        <main style={{ width: "100%" }}>{children}</main>
      </motion.div>
    </div>
  );
};

export default SideBar;
