import * as React from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import IconButton from "@mui/material/IconButton";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import LocationOnIcon from "@mui/icons-material/LocationOn";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import EmailIcon from "@mui/icons-material/Email";

import FacebookIcon from "@mui/icons-material/Facebook";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import TwitterIcon from "@mui/icons-material/X";

function Copyright() {
  return (
    <Typography variant="body2" color="white" fontSize="1.1rem" mt={1}>
      {"Copyright © "}
      <Link href="#" color="white">
        Metadew Technologies&nbsp;
      </Link>
      {new Date().getFullYear()}
    </Typography>
  );
}

export default function Footer({ setView }) {
  const handleMenuItemClick = (view) => () => {
    setView(view);
    window.scrollTo(0, 0); // Scroll to the top of the page
  };

  return (
    <Box
      sx={{
        width: "100%",
        backgroundColor: "#026bd4",
        borderTop: "1px solid",
        borderColor: "divider",
        py: { xs: 8, sm: 10, md: 8 },
        position: "relative",
        bottom: 0,
      }}
    >
      <Container
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: { xs: 4, sm: 8, md: 8 },
          textAlign: { sm: "center", md: "left" },
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", sm: "row" },
            width: "100%",
            justifyContent: "space-around",
            gap: { xs: 2, sm: 4 },
          }}
        >
          <Box
            sx={{
              display: { xs: "none", sm: "flex" },
              flexDirection: "column",
              gap: 1,
            }}
          >
            <Typography variant="body1" fontWeight="medium" color="white" fontSize="1.2rem">
              Useful Links
            </Typography>
            <Link
              color="white"
              variant="body2"
              onClick={handleMenuItemClick("about")}
              sx={{ cursor: "pointer", fontSize: "1.1rem" }} // Increased font size
            >
              About
            </Link>
            <Link
              color="white"
              variant="body2"
              onClick={handleMenuItemClick("contact")}
              sx={{ cursor: "pointer", fontSize: "1.1rem" }}
            >
              Contact
            </Link>
          </Box>
          <Box
            sx={{
              display: { xs: "none", sm: "flex" },
              flexDirection: "column",
              gap: 1,
            }}
          >
            <Typography variant="body1" fontWeight="medium" color="white" fontSize="1.2rem">
              Policies
            </Typography>
            <Link
              color="white"
              variant="body2"
              onClick={handleMenuItemClick("policyPage")}
              sx={{ cursor: "pointer", fontSize: "1.1rem" }}
            >
              Privacy Policy
            </Link>
            <Link
              color="white"
              variant="body2"
              onClick={handleMenuItemClick("policyPage")}
              sx={{ cursor: "pointer", fontSize: "1.1rem" }}
            >
              Terms and Conditions
            </Link>
            <Link
              color="white"
              variant="body2"
              onClick={handleMenuItemClick("policyPage")}
              sx={{ cursor: "pointer", fontSize: "1.1rem" }}
            >
              Ticket Policies
            </Link>
          </Box>
          <Box
            sx={{
              display: { xs: "none", sm: "flex" },
              flexDirection: "column",
              gap: 1,
            }}
          >
            <Typography variant="body1" fontWeight="body2" color="white" fontSize="1.2rem">
              <LocationOnIcon fontSize="medium" sx={{ mt: "1px", mr: "4px" }} />
              246/6B, Kumarasinghe Road, Badulla
            </Typography>
            <Typography variant="body2" color="white" fontSize="1.1rem">
              <LocalPhoneIcon fontSize="medium" sx={{ mt: "1px", mr: "4px" }} />
              +94 766333030 | +94 775333030
            </Typography>
            <Typography variant="body2" color="white" fontSize="1.1rem">
              <EmailIcon fontSize="medium" sx={{ mt: "1px", mr: "4px" }} />
              info@vishmitha.lk
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            pt: { xs: 4, sm: 8 },
            width: "100%",
            borderTop: "1px solid",
            borderColor: "divider",
            mt: 4, // Added margin top to separate from the content
          }}
        >
          <Copyright />
          <Stack
            direction="row"
            spacing={1}
            sx={{
              color: "white",
            }}
          >
            <IconButton
              color="inherit"
              href="https://facebook.com/mui"
              aria-label="Facebook"
            >
              <FacebookIcon />
            </IconButton>
            <IconButton
              color="inherit"
              href="https://twitter.com/MaterialUI"
              aria-label="Twitter"
            >
              <TwitterIcon />
            </IconButton>
            <IconButton
              color="inherit"
              href="https://www.linkedin.com/company/mui/"
              aria-label="LinkedIn"
            >
              <LinkedInIcon />
            </IconButton>
          </Stack>
        </Box>
      </Container>
    </Box>
  );
}
