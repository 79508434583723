import * as React from "react";
import { Box, Container, Typography, Grid, Card } from "@mui/material";

export default function Policy() {
  return (
    <Box
      id="policy"
      sx={(theme) => ({
        width: "100%",
        backgroundImage:
          theme.palette.mode === "light"
            ? "radial-gradient(ellipse 90% 90% at 50% -20%, hsl(210, 100%, 90%), transparent)"
            : "radial-gradient(ellipse 80% 50% at 50% -20%, hsl(210, 100%, 16%), transparent)",
        backgroundRepeat: "no-repeat",
      })}
    >
      <Container id="policies" sx={{ py: { xs: 15, sm: 16 } }}>
        <Typography
          component="h2"
          variant="h2"
          sx={{
            fontWeight: 'bold',
            fontSize: { xs: '2rem', sm: '2.5rem', md: '3rem' },
            mb: 2,
            textAlign: 'center',
          }}
        >
          Vishmitha HighWay Policies
        </Typography>

        {/* Cancellation and Refund Policy */}
        <Typography variant="h5" sx={{ mt: 6, fontWeight: 'bold' }}>
          Return Policy
        </Typography>
        <Typography variant="body1" sx={{ mt: 2 }}>
          At Vishmitha HighWay, we strive to provide a hassle-free booking experience for our passengers. However, we understand that plans may change, and passengers may need to cancel or modify their bookings.
        </Typography>
        <Typography variant="body2" sx={{ mt: 2 }}>
          <strong>Cancellation:</strong> Passengers can cancel their bookings up to 24 hours before the scheduled departure time to receive a full refund. Cancellations made less than 24 hours before departure are non-refundable.
        </Typography>
        <Typography variant="body2" sx={{ mt: 2 }}>
          <strong>Refund Process:</strong> Refunds will be processed within 5-7 business days to the original payment method used during booking.
        </Typography>
        <Typography variant="body2" sx={{ mt: 2 }}>
          <strong>Changes and Modifications:</strong> Passengers can change their booking up to 12 hours before departure, subject to availability. Changes requested after this period may not be possible.
        </Typography>
        <Typography variant="body2" sx={{ mt: 2 }}>
          Vishmitha HighWay reserves the right to modify or cancel any booking in case of unforeseen circumstances such as extreme weather or technical issues. In such cases, affected passengers will be notified and offered a full refund or an alternative arrangement.
        </Typography>

        {/* Privacy Policy */}
        <Typography variant="h5" sx={{ mt: 6, fontWeight: 'bold' }}>
          Privacy Policy
        </Typography>
        <Typography variant="body1" sx={{ mt: 2 }}>
          At Vishmitha HighWay, we are committed to protecting your privacy and ensuring that your personal information is secure. This Privacy Policy outlines how we collect, use, and safeguard your information when using our online booking platform.
        </Typography>
        <Typography variant="body2" sx={{ mt: 2 }}>
          <strong>Information Collection:</strong> We collect personal details such as name, contact information, and payment details when you make a booking. This data is used solely for processing your booking and improving our services.
        </Typography>
        <Typography variant="body2" sx={{ mt: 2 }}>
          <strong>Data Protection:</strong> We implement strict security measures to protect your personal data from unauthorized access or misuse. Your payment information is encrypted and stored securely.
        </Typography>
        <Typography variant="body2" sx={{ mt: 2 }}>
          <strong>Third-Party Services:</strong> We do not sell or share your personal information with third parties except for payment processors and partners involved in your transaction. These parties are bound by confidentiality agreements.
        </Typography>
        <Typography variant="body2" sx={{ mt: 2 }}>
          <strong>Cookies and Analytics:</strong> We use cookies to enhance your browsing experience and collect analytics data to improve our platform. You can manage cookie preferences through your browser.
        </Typography>

        {/* Business Terms and Conditions */}
        <Typography variant="h5" sx={{ mt: 6, fontWeight: 'bold' }}>
          Business Terms and Conditions
        </Typography>
        <Typography variant="body1" sx={{ mt: 2 }}>
          These terms govern your use of the Vishmitha HighWay online booking platform. By accessing or using our services, you agree to comply with these terms.
        </Typography>
        <Typography variant="body2" sx={{ mt: 2 }}>
          <strong>Service Availability:</strong> We provide bus booking services for the Badulla to Colombo route and are not responsible for delays or cancellations caused by external factors.
        </Typography>
        <Typography variant="body2" sx={{ mt: 2 }}>
          <strong>Booking Confirmation:</strong> All bookings are subject to availability. You will receive an email or SMS with your ticket details after confirmation.
        </Typography>
        <Typography variant="body2" sx={{ mt: 2 }}>
          <strong>Passenger Responsibility:</strong> Passengers must carry a valid ID during travel and arrive at the boarding point at least 15 minutes before departure.
        </Typography>
        <Typography variant="body2" sx={{ mt: 2 }}>
          <strong>Limitation of Liability:</strong> Vishmitha HighWay is not liable for damages resulting from missed buses, delays, or incorrect ticket details.
        </Typography>
        <Typography variant="body2" sx={{ mt: 2 }}>
          <strong>Payment:</strong> All payments must be made through our secure platform. Any disputes must be reported within 48 hours of booking.
        </Typography>
      </Container>
    </Box>
  );
}
