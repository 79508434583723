import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Container,
  Grid,
  Card,
  Paper,
  Select,
  FormControl,
  MenuItem,
} from "@mui/material";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import dayjs from "dayjs";

//assets
import loginBackground from "../../../assets/images/login_background.jpg";
// import loginBackground from "../../../assets/images/C5C_5508.jpg";

import { getLocationList } from "../../../services/busServices";
import Loading from "../Loading/Loading";

export default function Hero({
  loading,
  handleSubmit,
  setPickup,
  setDrop,
  setDate,
  pickup,
  drop,
  date,
}) {
  const [allLocations, setAllLocations] = useState({});

  const locationData = async () => {
    try {
      // setLoading(true);
      const res = await getLocationList();
      // console.log(res);
      setAllLocations(res);
      // setLoading(false);
    } catch (error) {
      console.error("Error fetching location details:", error.message);
    }
  };
  // console.log(loading, "in Hero");
  useEffect(() => {
    locationData();
  }, []);

  const handlePickup = (event) => {
    setPickup(event.target.value);
  };

  const handleDrop = (event) => {
    setDrop(event.target.value);
  };

  return loading ? (
    <Loading />
  ) : (
    <Box
      id="hero"
      sx={(theme) => ({
        width: "100%",
        backgroundImage:
          theme.palette.mode === "light"
            ? "radial-gradient(ellipse 80% 80% at 50% -20%, hsl(210, 100%, 90%), transparent)"
            : "radial-gradient(ellipse 80% 50% at 50% -20%, hsl(210, 100%, 16%), transparent)",
        backgroundRepeat: "no-repeat",
      })}
    >
      <Container
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          pt: { xs: 14, sm: 18 },
          pb: { xs: 1, sm: 12, md: 4 },
          maxWidth: "none !important",
        }}
      >
        <Card
          id="image"
          sx={{
            width: "100%",
            backgroundRepeat: "no-repeat",
            backgroundColor: (t) =>
              t.palette.mode === "light"
                ? t.palette.grey[50]
                : t.palette.grey[900],
            backgroundImage: `url(${loginBackground})`,
            backgroundSize: "cover",
            backgroundPosition: "left",
          }}
        >
          <Grid container component="main" p={2}>
            <Grid item xs={false} sm={4} md={7}></Grid>
            <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6}>
              <Box
                component="form"
                noValidate
                onSubmit={handleSubmit}
                sx={{
                  my: 8,
                  mx: 4,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <FormControl sx={{ margin: "normal" }} fullWidth>
                      <Select
                        value={pickup}
                        onChange={handlePickup}
                        displayEmpty
                        inputProps={{ "aria-label": "Without label" }}
                        sx={{
                          borderRadius: "2px",
                          outline: "none",
                          color: "text.primary",
                        }}
                      >
                        <MenuItem value="" disabled>
                          Pickup Point
                        </MenuItem>
                        {Object.entries(allLocations).map(
                          ([location, value]) => (
                            <MenuItem key={location} value={location}>
                              {location}
                            </MenuItem>
                          )
                        )}
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <FormControl sx={{ margin: "normal" }} fullWidth>
                      <Select
                        value={drop}
                        onChange={handleDrop}
                        displayEmpty
                        inputProps={{ "aria-label": "Without label" }}
                      >
                        <MenuItem value="" disabled>
                          Dropping Point
                        </MenuItem>
                        {Object.entries(allLocations).map(
                          ([location, value]) => (
                            <MenuItem key={location} value={location}>
                              {location}
                            </MenuItem>
                          )
                        )}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <Container sx={{ px: "0px !important" }}>
                    {/* <DatePicker
                      minDate={dayjs()} // Set minDate to today using dayjs
                      value={date}
                      onChange={(newValue) => setDate(newValue)}
                      sx={{
                        marginTop: "15px",
                        width: "100%",
                      }}
                    /> */}
                    <DatePicker
                      minDate={dayjs()} // Set minDate to today
                      maxDate={dayjs().add(14, "day")} // Set maxDate to 2 weeks from today
                      value={date}
                      onChange={(newValue) => setDate(newValue)}
                      sx={{
                        marginTop: "15px",
                        width: "100%",
                      }}
                    />
                  </Container>
                </LocalizationProvider>
                <Button type="submit" variant="contained" sx={{ mt: 3, mb: 2 }}>
                  Find Tickets
                </Button>{" "}
              </Box>
            </Grid>
          </Grid>
        </Card>
      </Container>
    </Box>
  );
}
