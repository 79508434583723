// 'use strict'
import React, { Component } from 'react';

function ___$insertStyle(css) {
    if (!css) {
        return;
    }
    if (typeof window === 'undefined') {
        return;
    }

    var style = document.createElement('style');

    style.setAttribute('type', 'text/css');
    style.innerHTML = css;
    document.head.appendChild(style);
    return css;
}

function _interopDefault(ex) { return (ex && (typeof ex === 'object') && 'default' in ex) ? ex['default'] : ex; }

var React__default = _interopDefault(React);
var PropTypes = _interopDefault(require('prop-types'));
//C65102
___$insertStyle(".blank {\n  height: 30px;\n  width: 35px;\n}\n\n.seat {\n  background-color: #CCCCCC;\n  height: 35px;\n  width: 35px;\n  color: #fff;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n.seat__number {\n  font-size: 10px;\n}\n.seat--north {\n  border-top-left-radius: 8px;\n  border-top-right-radius: 8px;\n}\n.seat--south {\n  border-bottom-left-radius: 8px;\n  border-bottom-right-radius: 8px;\n}\n.seat--east {\n  border-top-left-radius: 8px;\n  border-bottom-left-radius: 8px;\n}\n.seat--west {\n  border-top-right-radius: 8px;\n  border-bottom-right-radius: 8px;\n}\n.seat--enabled {\n  cursor: pointer;\n}\n.seat--enabled:hover {\n  background-color: gray;\n}\n.seat--selected {\n  cursor: pointer;\n  background-color: #4CAF50;\n}\n.seat--reserved--male {\n  cursor: not-allowed;\n  background-color: blue;\n}\n\n.seat--counter {\n  cursor: not-allowed;\n  background-color: #0000FF;\n}\n\n.seat--reserved--female {\n  cursor: not-allowed;\n  background-color: #FF007F;\n}\n\n.seat-picker {\n  display: flex;\n  flex-direction: column;\n  text-align: center;\n  justify-content: center;\n  align-items: center;\n  width: max-content;\n}\n.seat-picker > *:not(:last-child) {\n  margin-bottom: 2px;\n}\n.seat-picker__row {\n  display: flex;\n  align-items: center;\n  justify-items: center;\n}\n.seat-picker__row > *:not(:last-child) {\n  margin-right: 2px;\n}\n.seat-picker__row__number {\n  font-weight: normal;\n  height: 28px;\n  width: 28px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  text-align: center;\n  color: #9E9E9E;\n}\n.seat-picker__row--enabled:hover {\n  background-color: #F5F5F5;\n}\n.seat-picker__row--selected {\n  background-color: #F5F5F5;\n}\n.seat-picker__row--enabled:hover > .seat-picker__row__number {\n  font-weight: 600;\n}\n.seat-picker__row--selected > .seat-picker__row__number {\n  font-weight: 600;\n}");

var classCallCheck = function (instance, Constructor) {
    if (!(instance instanceof Constructor)) {
        throw new TypeError("Cannot call a class as a function");
    }
};

var createClass = function () {
    function defineProperties(target, props) {
        for (var i = 0; i < props.length; i++) {
            var descriptor = props[i];
            descriptor.enumerable = descriptor.enumerable || false;
            descriptor.configurable = true;
            if ("value" in descriptor) descriptor.writable = true;
            Object.defineProperty(target, descriptor.key, descriptor);
        }
    }

    return function (Constructor, protoProps, staticProps) {
        if (protoProps) defineProperties(Constructor.prototype, protoProps);
        if (staticProps) defineProperties(Constructor, staticProps);
        return Constructor;
    };
}();

var _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
        var source = arguments[i];

        for (var key in source) {
            if (Object.prototype.hasOwnProperty.call(source, key)) {
                target[key] = source[key];
            }
        }
    }

    return target;
};

var inherits = function (subClass, superClass) {
    if (typeof superClass !== "function" && superClass !== null) {
        throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
    }

    subClass.prototype = Object.create(superClass && superClass.prototype, {
        constructor: {
            value: subClass,
            enumerable: false,
            writable: true,
            configurable: true
        }
    });
    if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
};

var possibleConstructorReturn = function (self, call) {
    if (!self) {
        throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
    }

    return call && (typeof call === "object" || typeof call === "function") ? call : self;
};

var RowNumber = function (_Component) {
    inherits(RowNumber, _Component);

    function RowNumber() {
        classCallCheck(this, RowNumber);
        return possibleConstructorReturn(this, (RowNumber.__proto__ || Object.getPrototypeOf(RowNumber)).apply(this, arguments));
    }

    createClass(RowNumber, [{
        key: 'render',
        value: function render() {
            return this.props.visible ? React__default.createElement(
                'div',
                { className: 'seat-picker__row__number' },
                this.props.rowNumber
            ) : null;
        }
    }]);
    return RowNumber;
}(Component);

RowNumber.propTypes = {
    rowNumber: PropTypes.string,
    visible: PropTypes.bool
};

var Row = function (_Component) {
    inherits(Row, _Component);

    function Row() {
        classCallCheck(this, Row);
        return possibleConstructorReturn(this, (Row.__proto__ || Object.getPrototypeOf(Row)).apply(this, arguments));
    }

    createClass(Row, [{
        key: 'render',
        value: function render() {
            var _props = this.props,
                visible = _props.visible,
                rowNumber = _props.rowNumber,
                isSelected = _props.isSelected;

            var className = 'seat-picker__row' + (isSelected ? ' seat-picker__row--selected' : ' seat-picker__row--enabled');
            return React__default.createElement(
                'div',
                { className: className },
                React__default.createElement(RowNumber, { rowNumber: rowNumber, visible: visible }),
                this.props.children
            );
        }
    }]);
    return Row;
}(Component);

Row.propTypes = {
    rowNumber: PropTypes.string,
    visible: PropTypes.bool,
    isSelected: PropTypes.bool,
    children: PropTypes.array
};

var Seat = function (_Component) {
    inherits(Seat, _Component);

    function Seat() {
        var _ref;

        var _temp, _this, _ret;

        classCallCheck(this, Seat);

        for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
            args[_key] = arguments[_key];
        }

        return _ret = (_temp = (_this = possibleConstructorReturn(this, (_ref = Seat.__proto__ || Object.getPrototypeOf(Seat)).call.apply(_ref, [this].concat(args))), _this), _this.handleClick = function () {
            !_this.props.isReservedMale && !_this.props.isReservedFemale && !_this.props.isCounterSeat && _this.props.selectSeat();
        }, _temp), possibleConstructorReturn(_this, _ret);
    }

    createClass(Seat, [{
        key: 'render',
        value: function render() {
            var _props = this.props,
                isSelected = _props.isSelected,
                isEnabled = _props.isEnabled,
                isReservedMale = _props.isReservedMale,
                isReservedFemale = _props.isReservedFemale,
                isCounterSeat = _props.isCounterSeat,
                orientation = _props.orientation;

            var className = 'seat' +
                (isSelected ? ' seat--selected' : '') +
                (!isSelected && isEnabled && !isReservedMale ? ' seat--enabled' : '') +
                (!isSelected && isEnabled && !isReservedFemale ? ' seat--enabled' : '') +
                (!isSelected && isEnabled && !isCounterSeat ? ' seat--enabled' : '') +
                (isReservedMale ? ' seat--reserved--male' : '') +
                (isReservedFemale ? ' seat--reserved--female' : '') +
                (isCounterSeat ? ' seat--counter' : '') +
                (' seat--' + (!orientation ? 'north' : orientation));
            return React__default.createElement(
                'div',
                { className: className, onClick: this.handleClick },
                React__default.createElement(
                    'span',
                    { className: 'seat__number' },
                    this.props.seatNumber
                )
            );
        }
    }]);
    return Seat;
}(Component);

Seat.propTypes = {
    isSelected: PropTypes.bool,
    isReservedMale: PropTypes.bool,
    isReservedFemale: PropTypes.bool,
    isCounterSeat: PropTypes.bool,
    isEnabled: PropTypes.bool,
    orientation: PropTypes.oneOf(['north', 'south', 'east', 'west']),
    seatNumber: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    selectSeat: PropTypes.func.isRequired
};
Seat.defaultProps = {
    isSelected: false
};

var Blank = function (_Component) {
    inherits(Blank, _Component);

    function Blank() {
        classCallCheck(this, Blank);
        return possibleConstructorReturn(this, (Blank.__proto__ || Object.getPrototypeOf(Blank)).apply(this, arguments));
    }

    createClass(Blank, [{
        key: 'render',
        value: function render() {
            return React__default.createElement('div', { className: 'blank' });
        }
    }]);
    return Blank;
}(Component);

var SeatPicker = function (_Component) {
    inherits(SeatPicker, _Component);

    function SeatPicker(props) {
        classCallCheck(this, SeatPicker);

        var _this = possibleConstructorReturn(this, (SeatPicker.__proto__ || Object.getPrototypeOf(SeatPicker)).call(this, props));

        _initialiseProps.call(_this);

        var rows = props.rows;

        var _this$getAlreadySelec = _this.getAlreadySelectedSeats(),
            selectedSeats = _this$getAlreadySelec.selectedSeats,
            size = _this$getAlreadySelec.size;

        _this.state = {
            selectedSeats: selectedSeats,
            size: size,
            rowLength: Math.max.apply(null, rows.map(function (row) {
                return row.length;
            }))
        };
        return _this;
    }

    createClass(SeatPicker, [{
        key: 'shouldComponentUpdate',
        value: function shouldComponentUpdate(nextProps, nextState) {
            return nextState.selectedSeats !== this.state.selectedSeats;
        }
    }, {
        key: 'render',
        value: function render() {
            return React__default.createElement(
                'div',
                { className: 'seat-picker' },
                this.renderRows()
            );
        }
    }, {
        key: 'renderRows',
        value: function renderRows() {
            var _this2 = this;

            var seats = this.state.selectedSeats;
            var _props = this.props,
                alpha = _props.alpha,
                visible = _props.visible;

            return this.props.rows.map(function (row, index) {
                var rowNumber = alpha ? String.fromCharCode('A'.charCodeAt(0) + index) : (index + 1).toString();
                var isSelected = !!seats[rowNumber];
                var props = {
                    visible: visible,
                    rowNumber: rowNumber,
                    isSelected: isSelected,
                    selectedSeat: null,
                    seats: row,
                    key: 'Row' + rowNumber,
                    selectSeat: _this2.selectSeat
                };

                return React__default.createElement(
                    Row,
                    _extends({ key: index }, props),
                    _this2.renderSeats(row, rowNumber, isSelected),
                    ' '
                );
            });
        }
    }, {
        key: 'renderSeats',
        value: function renderSeats(seats, rowNumber, isRowSelected) {
            var _this3 = this;

            var _state = this.state,
                selectedSeats = _state.selectedSeats,
                size = _state.size,
                rowLength = _state.rowLength;
            var maxReservableSeats = this.props.maxReservableSeats;

            var blanks = new Array(rowLength - seats.length > 0 ? rowLength - seats.length : 0).fill(0);
            var row = seats.map(function (seat, index) {
                if (seat === null) return React__default.createElement(Blank, { key: index });
                var isSelected = isRowSelected && _this3.includeSeat(selectedSeats, rowNumber, seat.number);
                var props = {
                    isSelected: isSelected,
                    orientation: seat.orientation,
                    isReservedMale: seat.isReservedMale,
                    isReservedFemale: seat.isReservedFemale,
                    isCounterSeat: seat.isCounterSeat,
                    isEnabled: size < maxReservableSeats,
                    selectSeat: _this3.selectSeat.bind(_this3, rowNumber, seat.number, seat.id),
                    seatNumber: seat.number,
                    key: index
                };
                return React__default.createElement(Seat, props);
            });
            if (blanks.length > 0) {
                blanks.forEach(function (blank, index) {
                    row.push(React__default.createElement(Blank, { key: row.length + index + 1 }));
                });
            }
            return row;
        }
    }], [{
        key: 'getDerivedStateFromProps',
        value: function getDerivedStateFromProps(props, state) {
            if (props.maxReservableSeats < state.size) {
                var sum = 0;
                var selectedSeats = {};
                for (var array in state.selectedSeats) {
                    if (sum + state.selectedSeats[array].length < props.maxReservableSeats) {
                        selectedSeats[array] = state.selectedSeats[array].slice(0);
                    } else {
                        var dif = props.maxReservableSeats - sum;
                        selectedSeats[array] = state.selectedSeats[array].slice(0, dif);
                        return {
                            selectedSeats: selectedSeats,
                            size: props.maxReservableSeats
                        };
                    }
                    sum = sum + state.selectedSeats[array].length;
                }
            }
            return null;
        }
    }]);
    return SeatPicker;
}(Component);
SeatPicker.propTypes = {
    addSeatCallback: PropTypes.func,
    alpha: PropTypes.bool,
    visible: PropTypes.bool,
    selectedByDefault: PropTypes.bool,
    removeSeatCallback: PropTypes.func,
    maxReservableSeats: PropTypes.number,
    rows: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.shape({
        number: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        isReservedMale: PropTypes.bool,
        isReservedFemale: PropTypes.bool,
        isCounterSeat: PropTypes.bool,
        isSelected: PropTypes.bool
    }))).isRequired
};
SeatPicker.defaultProps = {
    addSeatCallback: function addSeatCallback(row, number, id) {

        console.log('Added seat ' + number + ', row ' + row + ', id ' + id);
    },
    removeSeatCallback: function removeSeatCallback(row, number, id) {
        console.log('Removed seat ' + number + ', row ' + row + ', id ' + id);
    },
    maxReservableSeats: 0
};

var _initialiseProps = function _initialiseProps() {
    var _this4 = this;

    this.getAlreadySelectedSeats = function () {
        var selectedSeats = {};
        var size = 0;
        var _props2 = _this4.props,
            maxReservableSeats = _props2.maxReservableSeats,
            alpha = _props2.alpha,
            selectedByDefault = _props2.selectedByDefault;

        if (selectedByDefault) {
            _this4.props.rows.forEach(function (row, index) {
                var rowNumber = alpha ? String.fromCharCode('A'.charCodeAt(0) + index) : (index + 1).toString();
                row.forEach(function (seat, index) {
                    if (seat && seat.isSelected) {
                        var seatAlreadySelected = _this4.includeSeat(selectedSeats, rowNumber, seat.number);
                        if (size < maxReservableSeats && !seatAlreadySelected) {
                            selectedSeats = _this4.addSeat(selectedSeats, rowNumber, seat.number);
                            size = size + 1;
                        }
                    }
                });
            });
        }
        return { selectedSeats: selectedSeats, size: size };
    };

    this.includeSeat = function (selectedSeats, row, number) {
        if (selectedSeats[row]) {
            return selectedSeats[row].includes(number);
        }
        return false;
    };

    this.addSeat = function (selectedSeats, row, number) {
        if (selectedSeats[row]) {
            if (!selectedSeats[row].includes(number)) {
                selectedSeats[row].push(number);
            }
        } else {
            selectedSeats[row] = [];
            selectedSeats[row].push(number);
        }
        return _extends({}, selectedSeats);
    };

    this.deleteSeat = function (row, number) {
        var selectedSeats = _this4.state.selectedSeats;

        if (selectedSeats[row]) {
            selectedSeats[row] = selectedSeats[row].filter(function (value) {
                return value !== number;
            });
            if (!selectedSeats[row].length > 0) {
                delete selectedSeats[row];
            }
        }
        return _extends({}, selectedSeats);
    };

    this.selectSeat = function (row, number, id) {
        var selectedSeats = _this4.state.selectedSeats;

        var size = _this4.state.size;
        var _props3 = _this4.props,
            maxReservableSeats = _props3.maxReservableSeats,
            addSeatCallback = _props3.addSeatCallback,
            removeSeatCallback = _props3.removeSeatCallback;

        var seatAlreadySelected = _this4.includeSeat(selectedSeats, row, number);

        if (size < maxReservableSeats && !seatAlreadySelected) {
            _this4.setState({
                selectedSeats: _this4.addSeat(selectedSeats, row, number),
                size: size + 1
            }, function () {
                return addSeatCallback(row, number, id);
            });
        } else if (selectedSeats[row] && seatAlreadySelected) {
            _this4.setState({
                selectedSeats: _this4.deleteSeat(row, number),
                size: size - 1
            }, function () {
                return removeSeatCallback(row, number, id);
            });
        }
    };
};

export default SeatPicker;