import { auth, db } from "../config/firebase";
import { app_log } from "../config/logs";
import {
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  GoogleAuthProvider,
  signInWithPopup,
} from "firebase/auth";
import { setDoc, doc } from "firebase/firestore";

export async function handleLogout() {
  try {
    await auth.signOut();
    localStorage.clear(); //clear the local storage
    window.location.href = "/";
    // app_log("info", "User logged out successfully!", "handleLogout");
  } catch (error) {
    // app_log("error", "User logged successfully!", "handleLogout");
  }
}

export async function signInDefault(email, password) {
  try {
    const userCredential = await signInWithEmailAndPassword(
      auth,
      email,
      password
    );
    // app_log(
    //   "info",
    //   "User logged successfully!",
    //   "signInDefault",
    //   userCredential
    // );
  } catch (error) {
    // app_log("error", "User logged successfully!", "signInDefault");
  }
}

export async function registerInDefault(email, password, fname, lname) {
  try {
    await createUserWithEmailAndPassword(auth, email, password);
    const user = auth.currentUser;
    if (user) {
      await setDoc(doc(db, "users", user.uid), {
        email: user.email,
        firstName: fname,
        lastName: lname,
        photo: "",
      });
    }
    // app_log("info", "User registered successfully!", "registerInDefault");
  } catch (error) {
    // app_log("error", "User registered successfully!", "registerInDefault");
  }
}

export async function signInWithGoogle() {
  try {
    const provider = new GoogleAuthProvider();
    await signInWithPopup(auth, provider).then(async (result) => {
      // console.log(result);
      const user = result.user;
      if (result.user) {
        await setDoc(doc(db, "users", user.uid), {
          email: user.email,
          firstName: user.displayName,
          photo: user.photoURL,
          lastName: "",
        });
      }
    });
    // app_log("info", "User logged successfully!", "signInGoogle");
  } catch (error) {
    // app_log("error", "User logged successfully!", "signInGoogle");
  }
}
